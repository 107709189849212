/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Link } from "gatsby";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";

const RecurringGeneralDonationSRCM = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "General Donation Recurring SRCM, USA";
    return `<gatsby_donation donationId = ${id}  btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "General Donation Recurring SRCM, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("General_Donation_Recurring_SRCM,_USA", event),
    []
  );

  return (
    <>
      <DonationLayout
        seoData={{
          title: "USAbhyasis [Mobile] - Donations",
        }}
      >
        <Box>
          <div
            className="row"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <div
              className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center"
              style={{
                marginTop: "15px",
              }}
            >
              <PageContent
                eventCallback={eventCallback}
                pageContent={pageContent(23, "Donate To SRCM")}
              />
            </div>
            <div
              className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                padding: "8px",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  backgroundColor: "rgb(77, 143, 21)",
                  padding: "8px 25px",
                  borderRadius: "25px",
                  color: "white",
                  border: "0px",
                  width: "190px",
                  height: "38px",
                }}
              >
                <Link
                  to="/recurring-general-donation-smsf-usa"
                  sx={{
                    color: "white",
                    ":hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                  Donate To SMSF
                </Link>
              </Box>
            </div>
            <div
              className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                padding: "8px",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  backgroundColor: "rgb(77, 143, 21)",
                  padding: "8px 25px",
                  borderRadius: "25px",
                  color: "white",
                  border: "0px",
                  width: "160px",
                  height: "38px",
                }}
              >
                <Link
                  to="/general-donations-to-heartfulness-institute-usa-recurring"
                  sx={{
                    color: "white",
                    ":hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                  Donate To HI
                </Link>
              </Box>
            </div>
          </div>
        </Box>
      </DonationLayout>
    </>
  );
};

export default RecurringGeneralDonationSRCM;
